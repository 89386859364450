import $ from 'jquery';
import { replaceTextNodesForAccessibility } from './util/dom';

$(document).ready(function () {
	window.requestAnimationFrame(() => {
		if (window.languageStringsForAccessibility) {
			replaceTextNodesForAccessibility(document.body, window.languageStringsForAccessibility);
		}
	});
});
